import React, { Component } from "react";
import { Label, Table, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import moment from "moment";
import DatePicker from "../../../core/form/datePicker";
import { Resources } from "../../popApi/redux/apiKeyRedux"
import { getHourMinutesAndSeconds } from "../../../helper";
import { Input, Dialog } from "../../../core";
import {
  required,
  checkHourValue,
  checkMinValue,
  checkRestrictionMinValue,
  checkSecValue,
  validateRestrictionCampaignDuration,
  isTelegramId,
  isValidDomainId,
  isValidApiKey,
  mailTime2BeforeMailTime1
} from "../../../helper/validation";
import { checkOverlap } from "../../calendar/utils/eventOverlapChecker";

import SelectAsync from "../../../core/form/selectAsyncClass";
import { Tooltip } from "../../../core";
import RouteAllowed from "../../../helper/isRouteAllowed";

class DomainDetail extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.state = {
      disableInfoUpdateButton: true,
      disableFeatureUpdateButton: true,
      disableInventoryUpdateButton: true,
      defaultCampaignUpdateButton: true,
      subscription_start_date: undefined,
      subscription_end_date: undefined,
      showSubscriptionDateError: false,
      isSectionRangeInvalid: false,
      isSectionRangeOverlapped: false,
      isSectionFormIncomplete: false,
      disableRemoveSection: false,
      mockDate: { year: 2001, month: 1, date: 1 },
      dialogModal: false,
    };
  }

  componentDidMount = () => {
    const { attributes } = this.props.domains[0];
    this.getApiKey();
    if (
      this.state.subscription_start_date == undefined &&
      attributes.subscriptionStartDate
    ) {
      this.setState({
        subscription_start_date: moment(
          attributes.subscriptionStartDate
        ).toDate()
      });
    }

    if (
      this.state.subscription_end_date == undefined &&
      attributes.subscriptionEndDate
    ) {
      this.setState({
        subscription_end_date: moment(attributes.subscriptionEndDate).toDate()
      });
    }
  };

  getApiKey = async () => {
    const { getPopKey } = Resources;
    getPopKey.url = `domains/${this.props.match.params.id}/pop_api_keys`
    await this.props.Get(getPopKey).then(data => {
      getPopKey.url = "pop_api_keys"
    });
  }

  enableUpdateButton = (flag, category) => {
    switch (category) {
      case "info":
        this.setState({ disableInfoUpdateButton: flag });
        break;
      case "feature":
        this.setState({ disableFeatureUpdateButton: flag });
        break;
      case "inventory":
        this.setState({ disableInventoryUpdateButton: flag });
        break;
      case "all":
        this.setState({
          disableInfoUpdateButton: flag,
          disableFeatureUpdateButton: flag,
          disableInventoryUpdateButton: flag
        });
        break;
      default:
        this.setState({
          disableInfoUpdateButton: true,
          disableFeatureUpdateButton: true,
          disableInventoryUpdateButton: true
        });
    }
  };

  enableDefaultCampaignUpdateButton = flag => {
    this.setState({ defaultCampaignUpdateButton: flag });
  };

  handleSubscriptionStartDateChange = (input, flag, category) => {
    if (
      this.state.subscription_end_date &&
      moment(this.state.subscription_end_date).isAfter(input)
    ) {
      this.setState({
        subscription_start_date: input,
        showSubscriptionDateError: false
      });
      this.enableUpdateButton(flag, category);
    } else {
      this.setState({
        subscription_start_date: input,
        showSubscriptionDateError: true
      });
      this.enableUpdateButton(!flag, category);
    }
  };

  handleSubscriptionEndDateChange = (input, flag, category) => {
    if (
      this.state.subscription_start_date &&
      moment(input).isAfter(this.state.subscription_start_date)
    ) {
      this.setState({
        subscription_end_date: input,
        showSubscriptionDateError: false
      });
      this.enableUpdateButton(flag, category);
    } else {
      this.setState({
        subscription_end_date: input,
        showSubscriptionDateError: true
      });
      this.enableUpdateButton(!flag, category);
    }
  };

  addSection = () => {
    this.setState({ disableRemoveSection: false });
    const newSection = {
      id: this.props.inventorySections.length,
      title: undefined,
      start_time: undefined,
      end_time: undefined
    };
    const updatedSections = this.props.inventorySections.map(
      (section, index) => {
        section.id = index;
        return section;
      }
    );
    this.props.change("inventorySections", [...updatedSections, newSection]);
    this.enableUpdateButton(true, "inventory");
    this.setState({ isSectionFormIncomplete: true });
  };

  removeSection = sectionId => {
    const filteredSections = this.props.inventorySections.filter(
      section => section.id !== sectionId
    );
    if (filteredSections.length <= 1) {
      this.setState({ disableRemoveSection: true });
    }
    var isFormIncomplete = false;
    filteredSections.map((section, index) => {
      if (!section.title || !section.start_time || !section.end_time) {
        isFormIncomplete = true;
      }
      section.id = index;
      return section;
    });
    this.props.change("inventorySections", filteredSections);
    this.setState({
      isSectionFormIncomplete: isFormIncomplete,
      disableUpdateButton: isFormIncomplete
    });
    this.validateSectionRange();
    this.validateSectionOverlap();
  };

  handleSectionFieldInputChange = (input, index, field) => {
    var isFormIncomplete = false;
    if (input) {
      const updatedSections = this.props.inventorySections.map(i => {
        if (i.id === index) {
          i[field] = input;
        }
        if (!i.title || !i.start_time || !i.end_time) {
          isFormIncomplete = true;
        }
        return i;
      });
      this.props.change("inventorySections", updatedSections);
    } else {
      isFormIncomplete = true;
    }
    this.validateSectionRange();
    this.validateSectionOverlap();
    this.setState({
      isSectionFormIncomplete: isFormIncomplete,
      disableInventoryUpdateButton: isFormIncomplete
    });
  };

  validateSectionRange = () => {
    var isInvalid = false;
    this.props.inventorySections
      .filter(section => section.start_time && section.end_time)
      .map(section => {
        if (
          moment(section.start_time)
            .set(this.state.mockDate)
            .isAfter(moment(section.end_time).set(this.state.mockDate)) &&
          !isInvalid
        ) {
          isInvalid = true;
        }
      });
    this.setState({ isSectionRangeInvalid: isInvalid });
  };

  validateSectionOverlap = () => {
    const sectionsToValidate = this.props.inventorySections
      .filter(section => section.start_time && section.end_time)
      .map(section => {
        return {
          start: moment(section.start_time).set(this.state.mockDate),
          end: moment(section.end_time).set(this.state.mockDate),
          title: section.title
        };
      });
    const res = checkOverlap(sectionsToValidate);
    this.setState({ isSectionRangeOverlapped: res.length > 1 ? true : false });
  };

  isInventorySectionFormInvalid() {
    return (
      this.state.isSectionFormIncomplete ||
      this.state.isSectionRangeInvalid ||
      this.state.isSectionRangeOverlapped
    );
  }

  handleDomainUpdate = values => {
    if (!values.subscription_start_date) {
      values.subscription_start_date = this.state.subscription_start_date;
    }
    if (!values.subscription_end_date) {
      values.subscription_end_date = this.state.subscription_end_date;
    }
    this.props.editDomainDetails(values);
    this.enableUpdateButton(true, "all");
  };

  handleCopy = (value) => {
    this.setState({ copied: true },
      () => {
        navigator.clipboard.writeText(value);
        setTimeout(() => this.setState({ copied: false }), 2000);
      }
    )
  }

  handleRegenerateButton = () => {
    // event.preventDefault()
    this.props.popKey.key
      ? this.toggleDialogModal()
      : this.regenerateKey()
  }

  toggleDialogModal = () => {
    this.setState(prevState => {
      return { dialogModal: !prevState.dialogModal }
    }
    )
  }

  regenerateKey = async () => {
    const { postPopKey } = Resources;
    postPopKey.url = `domains/${this.props.match.params.id}/pop_api_keys`
    await this.props.Post(postPopKey).then(() => {
      this.getApiKey();
      postPopKey.url = "pop_api_keys"
      this.setState({ dialogModal: false });
    });

  }


  render() {
    const { attributes } = this.props.domains[0];
    const time = getHourMinutesAndSeconds(attributes.screenshotTime);
    const {
      handleSubmit,
      domainDefaultCampaign,
      roles,
      permission,
      popKey
    } = this.props;

    return (
      <div className="row domainModal">
        <div className="col-md-7">
          <div className="networkInformation">
            <div className=" userProfile__info">
              <h4 className="userProfile__headerTitle">
                {" "}
                <i className="mr-3 bx bxs-pencil" />
                Edit Domain Info
              </h4>
              <form className="validate-form">
                <div>
                  <div className="userProfile__body nameField form-validation-arrange userinput">
                    <Field
                      label="Name"
                      component={Input}
                      name="name"
                      type="text"
                      validate={required}
                      placeholder="Name"
                      props={
                        (this.props.roles.includes("SuperAdmin") &&
                          attributes.name === "gurzu") ||
                          !this.props.roles.includes("SuperAdmin")
                          ? { disabled: true }
                          : { disabled: false }
                      }
                      onChange={() => this.enableUpdateButton(false, "info")}
                    />
                  </div>
                </div>
                <div>
                  <div className="userProfile__body  form-validation-arrange userinput">
                    <Label>Screenshot Time</Label>
                    {/* <div className="row">
                    <div className="col-md-4 domain-input-label text-center">
                      Hours
                    </div>
                    <br />
                    <div className=" col-md-4 domain-input-label text-center">
                      Minutes
                    </div>
                    <br />
                    <div className="col-md-4 domain-input-label text-center ">
                      Seconds
                    </div>
                  </div> */}
                    <div className="globalInput--mod domain-input-group">
                      <InputGroup>
                        <InputGroupAddon
                          className="mr-2 hour"
                          addonType="prepend"
                        >
                          HH
                        </InputGroupAddon>
                        <div className="domain-input-block">
                          <Field
                            component={Input}
                            name="hour"
                            type="number"
                            placeholder="HH"
                            label="hour"
                            validate={checkHourValue}
                            min={0}
                            max={24}
                            inputClass="domain-edit-input modal-duration "
                            onChange={() =>
                              this.enableUpdateButton(false, "info")
                            }
                          />
                        </div>
                        <InputGroupAddon className="mr-2 " addonType="prepend">
                          MM
                        </InputGroupAddon>
                        <div className="domain-input-block">
                          <Field
                            component={Input}
                            name="minute"
                            type="number"
                            placeholder="MM"
                            validate={checkMinValue}
                            min={1}
                            max={59}
                            inputClass="domain-edit-input modal-duration "
                            onChange={() =>
                              this.enableUpdateButton(false, "info")
                            }
                          />
                        </div>

                        <InputGroupAddon className="mr-2 " addonType="prepend">
                          SS
                        </InputGroupAddon>
                        <div className="domain-input-block">
                          <Field
                            component={Input}
                            name="second"
                            type="number"
                            placeholder="SS"
                            validate={checkSecValue}
                            min={0}
                            max={59}
                            inputClass="domain-edit-input modal-duration"
                            onChange={() =>
                              this.enableUpdateButton(false, "info")
                            }
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                </div>

                {this.props.roles.includes("SuperAdmin") &&
                  attributes.name !== "gurzu" && (
                    <div className="userProfile__body nameField form-validation-arrange userinput">
                      <label>Subscription Start Date</label>

                      <Field
                        component={DatePicker}
                        dateFormat="yyyy/MM/dd"
                        name="subscription_start_date"
                        placeholderText="mm/dd/yyyy"
                        selected={this.state.subscription_start_date}
                        onChange={input =>
                          this.handleSubscriptionStartDateChange(
                            input,
                            false,
                            "info"
                          )
                        }
                      />
                      {this.state.showSubscriptionDateError && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          End date must be greater than start date
                        </div>
                      )}
                    </div>
                  )}
                {this.props.roles.includes("SuperAdmin") &&
                  attributes.name !== "gurzu" && (
                    <div className="userProfile__body form-validation-arrange userinput">
                      <label>Subscription End Date</label>

                      <Field
                        component={DatePicker}
                        dateFormat="yyyy/MM/dd"
                        name="subscription_end_date"
                        placeholderText="mm/dd/yyyy"
                        selected={this.state.subscription_end_date}
                        onChange={input =>
                          this.handleSubscriptionEndDateChange(
                            input,
                            false,
                            "info"
                          )
                        }
                      />
                      {this.state.showSubscriptionDateError && (
                        <div
                          className="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          End date must be greater than start date
                        </div>
                      )}
                    </div>
                  )}
                {this.props.roles.includes("SuperAdmin") &&
                  attributes.name !== "gurzu" && (
                    <div>
                      <div className="userProfile__body form-validation-arrange userinput">
                        <Label>Plan</Label>

                        <div className="subInput">
                          <div className="subscriptionContainer">
                            <Field
                              name="subscription"
                              component={Input}
                              type="radio"
                              value="basic"
                              id="basic"
                              labelClass="noExtraLabelField"
                              checked={this.props.subscription === "basic"}
                              onChange={() =>
                                this.enableUpdateButton(false, "info")
                              }
                            />{" "}
                            <Label
                              for="basic"
                              className={`${this.props.subscription === "basic" &&
                                "activePlan"}`}
                            >
                              Basic Plan
                            </Label>
                          </div>
                          <div className="subscriptionContainer">
                            <Field
                              name="subscription"
                              component={Input}
                              type="radio"
                              value="premium"
                              id="premium"
                              labelClass="noExtraLabelField"
                              checked={this.props.subscription === "premium"}
                              onChange={() =>
                                this.enableUpdateButton(false, "info")
                              }
                            />{" "}
                            <Label
                              for="premium"
                              className={`${this.props.subscription ===
                                "premium" && "activePlan"}`}
                            >
                              Premium Plan
                            </Label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </form>
              <div className="userProfile__changePassword mt-3">
                <div className="d-flex justify-content-end">
                  <button
                    className="primaryButton mt-0"
                    type="submit"
                    onClick={handleSubmit(values =>
                      this.handleDomainUpdate(values)
                    )}
                    disabled={this.state.disableInfoUpdateButton}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>{" "}
          {this.props.roles.includes("SuperAdmin") && (
            <div className="networkInformation clearfix">
              <div className="userProfile__info">
                <h4 className="userProfile__headerTitle">
                  {" "}
                  <i className="mr-3 bx bxs-pencil" />
                  Domain Features
                </h4>
                <div className="row">
                  <div className="col mb-2">
                    <div className="userProfile__body form-validation-arrange userinput toggle-version restrict-rate-amount">
                      <Label>Enable party and brand feature</Label>
                      <Label className="mb-0 ml-1 mt-0 switch">
                        <Field
                          name="enablePartyBrandFeature"
                          id="partyFeatureFlag"
                          component="input"
                          type="checkbox"
                          checked={this.props.enablePartyBrandFeature}
                          onChange={event => {
                            if (
                              event.target.value === "true" ||
                              !event.target.value
                            ) {
                              this.props.dispatch(
                                change(
                                  "DomainEditForm",
                                  "contentRateAmountRestrictionFlag",
                                  false
                                )
                              );
                            }
                            this.enableUpdateButton(false, "feature");
                          }}
                        />
                        <span className="slider round" />
                      </Label>
                    </div>
                  </div>
                  <div className="col mb-2">
                    <div className="userProfile__body form-validation-arrange userinput toggle-version restrict-rate-amount">
                      <Label>Restrict Rate Amount Features</Label>
                      <Label className="mb-0 ml-1 mt-0 switch">
                        <Field
                          name="contentRateAmountRestrictionFlag"
                          id="contentRateAmountRestrictionFlag"
                          component="input"
                          type="checkbox"
                          checked={this.props.contentRateAmountRestrictionFlag}
                          onChange={e => {
                            this.enableUpdateButton(false, "feature");
                          }}
                          disabled={!this.props.enablePartyBrandFeature}
                        />
                        <span className="slider round" />
                      </Label>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col my-2">
                    <div className="userProfile__body form-validation-arrange userinput toggle-version restrict-rate-amount">
                      <Label>Enable Telegram Messaging</Label>
                      <Label className="mb-0 ml-1 mt-0 switch">
                        <Field
                          name="enableTelegramFeature"
                          id="telegramFeatureFlag"
                          checked={this.props.enableTelegramFeature}
                          label={undefined}
                          component="input"
                          type="checkbox"
                          onChange={event => {
                            if (event.target.value === "true") {
                              this.props.dispatch(
                                change("DomainEditForm", "telegramId", null)
                              );
                            }
                            this.enableUpdateButton(false, "feature");
                          }}
                        />
                        <span className="slider round" />
                      </Label>
                      <InputGroup className="row">
                        <div className="col-3">
                          <InputGroupAddon
                            addonType="prepend"
                          >
                            Channel ID
                          </InputGroupAddon>
                        </div>
                        <div className="col-9">
                          <Field
                            name="telegramId"
                            id="telegramId"
                            component={Input}
                            onChange={value => {
                              if (value == "") {
                                this.props.dispatch(
                                  change("DomainEditForm", "telegramId", null)
                                );
                              }
                              this.enableUpdateButton(false, "feature");
                            }}
                            disabled={!this.props.enableTelegramFeature}
                            placeholder="Telegram Id"
                            inputClass="pr-2"
                            validate={isTelegramId}
                          />
                        </div>
                      </InputGroup>
                      <InputGroup className="row" style={{ marginTop: "10px" }}>
                        <div className="col-3">
                          <InputGroupAddon addonType="prepend">
                            Mail Time 1
                          </InputGroupAddon>
                        </div>
                        <div className="col-9 domain-input-block mail-section-container">
                          <Tooltip
                            item={
                              <div >
                                <Field
                                  name="mailTime1"
                                  id="mailTime1"
                                  component={DatePicker}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={5}
                                  selected={this.props.mailTime1 && new Date(this.props.mailTime1)}
                                  timeCaption="Mail Time 1"
                                  dateFormat="hh:mm aa"
                                  onChange={value => {
                                    this.enableUpdateButton(false, "feature");
                                  }}
                                  placeholderText="hh:mm"
                                  validate={[]}
                                />
                              </div>
                            }
                            placement="bottom"
                            id="mail-time1"
                            tooltipContent={
                              <p style={{ paddingTop: "0px" }}>
                                <i class="bx bx-info-circle"></i>
                                {" "}User for both Email and Telegram message
                              </p>
                            }
                          />
                        </div>
                      </InputGroup>
                      <InputGroup className="row" style={{ marginTop: "10px" }}>
                        <div className="col-3">
                          <InputGroupAddon addonType="prepend">
                            Mail Time 2
                          </InputGroupAddon>
                        </div>
                        <div className="col-9 domain-input-block mail-section-container">
                          <Tooltip
                            item={
                              <div >
                                <Field
                                  name="mailTime2"
                                  id="mailTime2"
                                  component={DatePicker}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={5}
                                  selected={this.props.mailTime2 && new Date(this.props.mailTime2)}
                                  timeCaption="Mail Time 2"
                                  dateFormat="hh:mm aa"
                                  onChange={value => {
                                    this.enableUpdateButton(false, "feature");
                                  }}
                                  placeholderText="hh:mm"
                                  validate={[mailTime2BeforeMailTime1]}
                                />
                              </div>
                            }
                            placement="bottom"
                            id="mail-time2"
                            tooltipContent={
                              <p style={{ paddingTop: "0px" }}>
                                <i class="bx bx-info-circle"></i>
                                {" "}Second Telegram message time
                              </p>
                            }
                          />
                        </div>
                      </InputGroup>
                      <p style={{ paddingTop: "0px" }}>
                        <i class="bx bx-info-circle"></i>
                        {" "}Changes in mail time will be updated tomorrow
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col my-2">
                        <div className="userProfile__body form-validation-arrange userinput toggle-version restrict-rate-amount">
                          <Label>Enable social media feature</Label>
                          <Label className="mb-0 ml-1 mt-0 switch">
                            <Field
                              name="enableSmiFeature"
                              id="smiFeatureFlag"
                              checked={this.props.enableSmiFeature}
                              label={undefined}
                              component="input"
                              type="checkbox"
                              onChange={event => {
                                if (event.target.value === "true") {
                                  this.props.dispatch(
                                    change("DomainEditForm", "domainId", null)
                                  );
                                }
                                this.enableUpdateButton(false, "feature");
                              }}
                            />
                            <span className="slider round" />
                          </Label>
                          <InputGroup className="row">
                            <div className="col-3">
                              <InputGroupAddon
                                addonType="prepend"
                              >
                                SMI Domain ID
                              </InputGroupAddon>
                            </div>
                            <div className="col-9">
                              <Field
                                name="smiDomainId"
                                id="smiDomainId"
                                component={Input}
                                onChange={value => {
                                  if (value == "" && this.props.enableSmiFeature) {
                                    this.props.dispatch(
                                      change("DomainEditForm", "smiDomainId", null)
                                    );
                                  }
                                  this.enableUpdateButton(false, "feature");
                                }}
                                disabled={!this.props.enableSmiFeature}
                                placeholder="Domain Id"
                                inputClass="pr-2"
                                validate={
                                  this.props.enableSmiFeature && isValidDomainId
                                }
                              />
                            </div>
                          </InputGroup>
                          <InputGroup className="row" style={{ marginTop: "10px" }}>
                            <div className="col-3">
                              <InputGroupAddon addonType="prepend">
                                SMI API Key
                              </InputGroupAddon>
                            </div>
                            <div className="col-9">
                              <Field
                                name="smiApiKey"
                                id="smiApiKey"
                                component={Input}
                                onChange={value => {
                                  if (value == "" && this.props.enableSmiFeature) {
                                    this.props.dispatch(
                                      change("DomainEditForm", "smiApiKey", null)
                                    );
                                  }
                                  this.enableUpdateButton(false, "feature");
                                }}
                                disabled={!this.props.enableSmiFeature}
                                placeholder="Api Key"
                                inputClass="pr-2"
                                validate={
                                  this.props.enableSmiFeature && isValidApiKey
                                }
                              />
                            </div>
                          </InputGroup>
                        </div>
                      </div>
                      <div className="col my-2">
                        <div className="userProfile__body  form-validation-arrange userinput toggle-version">
                          <Label>Restrict Campaign</Label>
                          <Label className="mb-0 ml-1 mt-0 switch">
                            <Field
                              name="campaignRestrictionFlag"
                              id="campaignRestrictionFlag"
                              component="input"
                              type="checkbox"
                              checked={this.props.campaignRestrictionFlag}
                              onChange={() =>
                                this.enableUpdateButton(false, "feature")
                              }
                            />
                            <span className="slider round" />
                          </Label>
                          <div className="globalInput--mod domain-input-group">
                            <InputGroup
                              style={{
                                background:
                                  !this.props.campaignRestrictionFlag && "#e3e3ea"
                              }}
                            >
                              <InputGroupAddon
                                className="mr-2 hour"
                                addonType="prepend"
                              >
                                HH
                              </InputGroupAddon>
                              <div className="domain-input-block">
                                <Field
                                  component={Input}
                                  name="campaignRestrictHour"
                                  type="number"
                                  placeholder="HH"
                                  label="hour"
                                  validate={checkHourValue}
                                  min={0}
                                  max={24}
                                  inputClass="domain-edit-input modal-duration "
                                  onChange={() =>
                                    this.enableUpdateButton(false, "feature")
                                  }
                                  disabled={!this.props.campaignRestrictionFlag}
                                />
                              </div>
                              <InputGroupAddon
                                className="mr-2 "
                                addonType="prepend"
                              >
                                MM
                              </InputGroupAddon>
                              <div className="domain-input-block">
                                <Field
                                  component={Input}
                                  name="campaignRestrictMinute"
                                  type="number"
                                  placeholder="MM"
                                  validate={checkRestrictionMinValue}
                                  min={1}
                                  max={59}
                                  inputClass="domain-edit-input modal-duration "
                                  onChange={() =>
                                    this.enableUpdateButton(false, "feature")
                                  }
                                  disabled={!this.props.campaignRestrictionFlag}
                                />
                              </div>

                              <InputGroupAddon
                                className="mr-2 "
                                addonType="prepend"
                              >
                                SS
                              </InputGroupAddon>
                              <div className="domain-input-block">
                                <Field
                                  component={Input}
                                  name="campaignRestrictSecond"
                                  type="number"
                                  placeholder="SS"
                                  validate={[
                                    checkSecValue,
                                    validateRestrictionCampaignDuration
                                  ]}
                                  min={0}
                                  max={59}
                                  inputClass="domain-edit-input modal-duration"
                                  onChange={() =>
                                    this.enableUpdateButton(false, "feature")
                                  }
                                  disabled={!this.props.campaignRestrictionFlag}
                                />
                              </div>
                            </InputGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="userProfile__changePassword mt-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className="primaryButton mt-0"
                      type="submit"
                      onClick={handleSubmit(values =>
                        this.handleDomainUpdate(values)
                      )}
                      disabled={this.state.disableFeatureUpdateButton}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.props.roles.includes("SuperAdmin") && (
            <div className="networkInformation clearfix">
              <div className="userProfile__info">
                <div className="userProfile__headerTitle api_header">
                  <h4 className="pt-2 mb-auto">
                    {" "}
                    <i className="mr-3 bx bxs-pencil" />
                    Pop Api Key
                  </h4>

                  {popKey.key && <div className="header_buttons d-flex ">
                    <Tooltip
                      item={
                        <div>
                          <div
                            className={`iconButton fa ${this.state.copied ? "fa-check" : "fa-copy"}`}
                            onClick={(e) => {
                              e.preventDefault();
                              this.handleCopy(popKey.key)
                            }
                            }
                          />
                        </div>
                      }
                      id="copy-key"
                      tooltipContent="Copy to clipboard"
                    />
                    <Tooltip
                      item={
                        <div>
                          <div
                            className="iconButton fa fa-edit"
                            onClick={() => this.handleRegenerateButton()}
                          />
                        </div>
                      }
                      id="regen-key"
                      tooltipContent="Regenerate Key"
                    />
                  </div>}
                </div>
                <div
                  className="api_section"
                >
                  {popKey.key
                    ?
                    <p>
                      {popKey.key}
                    </p>
                    :
                    <Button
                      className="btn-outline-primary defaultButton"
                      type="button"
                      onClick={(e) => {
                        this.handleRegenerateButton(e);
                      }}
                    >
                      Generate Key
                    </Button>

                  }
                </div>
              </div>
              <Dialog
                showHandler={false}
                externalControl={true}
                modal={this.state.dialogModal}
                toggleModal={this.toggleDialogModal}
                body={
                  <span>Are you sure you want to regenerate key? </span>
                }
                primaryMethod={
                  this.regenerateKey
                }
                toggleModel={this.toggleDialogModal}
                title="Regenarate Key"
              />
            </div>
          )
          }
          <RouteAllowed
            roles={roles}
            permission={permission.domain.editInventorySection}
          >
            <div className="networkInformation clearfix">
              <div className="userProfile__info">
                <h4 className="userProfile__headerTitle">
                  {" "}
                  <i className="mr-3 bx bxs-pencil" />
                  Inventory Sections{" "}
                </h4>
                <div className="inventory-section-container">
                  {this.props.inventorySections.map(section => (
                    <div key={section.id} className="inventory-section-setting">
                      <div className="input-field">
                        <Field
                          label="Section Name"
                          component={Input}
                          name={`inventorySections[${section.id}].title`}
                          type="text"
                          placeholder="Section Name"
                          onBlur={e => {
                            this.handleSectionFieldInputChange(
                              e.target.value,
                              section.id,
                              "title"
                            );
                          }}
                        />
                      </div>

                      <div className="input-field">
                        <label>Start Time</label>
                        <Field
                          component={DatePicker}
                          dateFormat="hh:mm aa"
                          name={`inventorySections[${section.id}].start_time`}
                          placeholderText="hh:mm"
                          selected={section.start_time}
                          onChange={input =>
                            this.handleSectionFieldInputChange(
                              input,
                              section.id,
                              "start_time"
                            )
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Start Time"
                        />
                      </div>
                      <div className="input-field">
                        <label>End Time</label>
                        <Field
                          component={DatePicker}
                          dateFormat="hh:mm aa"
                          name={`inventorySections[${section.id}].end_time`}
                          placeholderText="hh:mm"
                          selected={section.end_time}
                          onChange={input =>
                            this.handleSectionFieldInputChange(
                              input,
                              section.id,
                              "end_time"
                            )
                          }
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="End Time"
                        />
                      </div>
                      <div className="remove-section-icon">
                        <Tooltip
                          item={
                            <i
                              role="presentation"
                              className="bx bx-trash"
                              onClick={() =>
                                !this.state.disableRemoveSection &&
                                this.removeSection(section.id)
                              }
                            />
                          }
                          id={`remove-section-${section.id}`}
                          tooltipContent="Remove Section"
                        />{" "}
                      </div>
                    </div>
                  ))}
                  <div className="add-section">
                    <Tooltip
                      item={
                        <a className="text" onClick={this.addSection}>
                          Add Section
                          <i
                            role="presentation"
                            className="bx bx-plus bx-sm icon"
                          />
                        </a>
                      }
                      id="add-section"
                      tooltipContent="Add Section"
                    />{" "}
                  </div>
                  {this.state.isSectionFormIncomplete && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      All inventory section fields are required.
                    </div>
                  )}
                  {this.state.isSectionRangeInvalid && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Start time of each sections shouldn't be greater than end
                      time.
                    </div>
                  )}
                  {this.state.isSectionRangeOverlapped && (
                    <div
                      className="invalid-feedback"
                      style={{ display: "block" }}
                    >
                      Time slots of different sections shouldn't overlap.
                    </div>
                  )}
                </div>
                <div className="userProfile__changePassword mt-3">
                  <div className="d-flex justify-content-end">
                    <button
                      className="primaryButton mt-0"
                      type="submit"
                      onClick={handleSubmit(values =>
                        this.handleDomainUpdate(values)
                      )}
                      disabled={
                        this.state.disableInventoryUpdateButton ||
                        this.isInventorySectionFormInvalid()
                      }
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </RouteAllowed>
          {this.props.currentUserRoles.id === this.props.domains[0].id && (
            <div className="networkInformation clearfix">
              <div className="userProfile__info">
                <h4 className="userProfile__headerTitle">
                  {" "}
                  <i className="mr-3 bx bxs-pencil" />
                  Set Default Campaign
                </h4>
                <form className="validate-form">
                  <div className="form-validation-arrange userinput default-campaign">
                    <Label>Default Campaign</Label>
                    <div className="d-flex form-validation-arrange userinput">
                      {/* <FormGroup> */}
                      <Field
                        component={SelectAsync}
                        name="default_campaign"
                        currentDefaultCampaign={
                          this.props.domainDefaultCampaign &&
                          this.props.domainDefaultCampaign.value
                        }
                        isClearable
                        onChange={() =>
                          this.enableDefaultCampaignUpdateButton(false)
                        }
                      />
                      <button
                        className="primaryButton ml-3 mt-0"
                        type="submit"
                        onClick={handleSubmit(values => {
                          this.props.editDefaultCampaign(values);
                          this.enableDefaultCampaignUpdateButton(true);
                        })}
                        disabled={this.state.defaultCampaignUpdateButton}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div>
                <Table
                  responsive
                  style={{ padding: "0 15px" }}
                  className="defaultCampaignTable"
                >
                  <thead>
                    <tr>
                      <th
                        className="pl-2"
                        style={{ width: "auto", padding: "0px" }}
                      >
                        Name
                      </th>
                      <th>Orientation</th>
                      <th style={{ width: "120px" }}>Status</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      key={domainDefaultCampaign && domainDefaultCampaign.value}
                    >
                      <td className="word-break">
                        {domainDefaultCampaign
                          ? domainDefaultCampaign.label
                          : "N/A"}
                      </td>
                      <td>
                        {domainDefaultCampaign
                          ? domainDefaultCampaign.orientation
                          : "N/A"}
                      </td>
                      <td className="word-break">
                        <span
                          className={`content-text ${domainDefaultCampaign.status === "live"
                            ? "content__status__online"
                            : domainDefaultCampaign.status
                              ? "content__status__offline"
                              : ""
                            }`}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>

        <div className="col-md-5">
          <div className="domainPlan">
            <h4 className="userProfile__headerTitle">Subscription Plan</h4>
            <div className="row">
              <div className="col-md-6">
                <span>Basic Plan</span>
                <ul>
                  <li>
                    {" "}
                    <div className="domainPlanIcon">
                      <i className="fas fa-check" />
                    </div>{" "}
                    Basic Feature Access
                  </li>

                  <li>
                    {" "}
                    <div className="domainPlanIcon">
                      <i className="fas fa-times" />
                    </div>{" "}
                    HLS Widget
                  </li>
                  <li>
                    <div className="domainPlanIcon">
                      <i className="fas fa-times" />
                    </div>{" "}
                    Youtube Widget
                  </li>
                  <li>
                    <div className="domainPlanIcon">
                      <i className="fas fa-times" />
                    </div>{" "}
                    Pic To Screen Widget
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <span>Premium Plan</span>
                <ul>
                  <li>
                    <div className="domainPlanIcon">
                      <i className="fas fa-check" />
                    </div>{" "}
                    Basic Feature Access
                  </li>

                  <li>
                    {" "}
                    <div className="domainPlanIcon">
                      <i className="fas fa-check" />
                    </div>{" "}
                    HLS Widget
                  </li>
                  <li>
                    {" "}
                    <div className="domainPlanIcon">
                      <i className="fas fa-check" />
                    </div>{" "}
                    Youtube Widget
                  </li>
                  <li>
                    {" "}
                    <div className="domainPlanIcon">
                      <i className="fas fa-check" />
                    </div>{" "}
                    Pic To Screen Widget
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function getSelectedDomainDetails(selectedDomains) {
  const domains = Object.keys(selectedDomains).map(
    key => selectedDomains[key].attributes
  );
  return domains;
}

export default connect(state => {
  const selector = formValueSelector("DomainEditForm");
  const formSelectorDomainRateAmountRestriction = selector(
    state,
    "contentRateAmountRestrictionFlag"
  );
  const formSelectorEnablePartyBrandFeature = selector(
    state,
    "enablePartyBrandFeature"
  );
  const formSelectorTelegramFeature = selector(state, "enableTelegramFeature");
  const formSelectorTelegramId = selector(state, "telegramId");
  const formSelectorMailTime1 = selector(state, "mailTime1");
  const formSelectorMailTime2 = selector(state, "mailTime2");

  const formSelectorSmiFeature = selector(state, "enableSmiFeature");
  const formSelectorSmiDomainId = selector(state, "smiDomainId");
  const formSelectorSmiApiKey = selector(state, "smiApiKey");
  return {
    subscription: selector(state, "subscription"),
    campaignRestrictionFlag: selector(state, "campaignRestrictionFlag"),
    contentRateAmountRestrictionFlag:
      formSelectorDomainRateAmountRestriction == undefined
        ? getSelectedDomainDetails(state.domainDetails.list.domains)[0]
          .isContentRateEnabled
        : formSelectorDomainRateAmountRestriction,
    rateAmountRestriction: getSelectedDomainDetails(
      state.domainDetails.list.domains
    )[0].isContentRateEnabled,
    inventorySections: selector(state, "inventorySections"),
    enablePartyBrandFeature:
      formSelectorEnablePartyBrandFeature === undefined
        ? getSelectedDomainDetails(state.domainDetails.list.domains)[0]
          .isPartyBrandFeatureEnabled
        : formSelectorEnablePartyBrandFeature,
    enableTelegramFeature:
      formSelectorTelegramFeature === undefined
        ? getSelectedDomainDetails(state.domainDetails.list.domains)[0]
          .isTelegramEnabled
        : formSelectorTelegramFeature,
    telegramId:
      formSelectorTelegramId === undefined
        ? getSelectedDomainDetails(state.domainDetails.list.domains)[0]
          .telegramId
        : formSelectorTelegramId,
    mailTime1:
      formSelectorMailTime1 === undefined
        ? getSelectedDomainDetails(state.domainDetails.list.domains)[0].mailTime1
        : formSelectorMailTime1,
    mailTime2:
      formSelectorMailTime2 === undefined
        ? getSelectedDomainDetails(state.domainDetails.list.domains)[0].mailTime2
        : formSelectorMailTime2,
    isTelegramEnabled: selector(state, "isTelegramEnabled"),
    enableSmiFeature:
      formSelectorTelegramFeature === undefined
        ? getSelectedDomainDetails(state.domainDetails.list.domains)[0]
          .isSmiEnabled
        : formSelectorSmiFeature,
    smiDomainId:
      formSelectorSmiDomainId === undefined
        ? getSelectedDomainDetails(state.domainDetails.list.domains)[0]
          .smiDomainId
        : formSelectorSmiDomainId,
    smiApiKey:
      formSelectorSmiApiKey === undefined
        ? getSelectedDomainDetails(state.domainDetails.list.domains)[0].smiDomainApiKey
        : formSelectorSmiApiKey,
    popKey: state.popKey
  };
})(
  reduxForm({
    form: "DomainEditForm"
  })(DomainDetail)
);
